import React, { useEffect, useState, Suspense }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import monitors from './utils/monitors'

import './mobile/mobile.scss';
import './App.scss';
import './components/icons.scss';
import './components/modal.scss';
import './components/progress.scss';
import './components/slider.scss';
import './components/form.scss';

// import GameApp from './game';
// import DutchAuctionApp from './dutch-auction'
// import TonConnectDApp from './da/tonconnect'

import {Buffer} from 'Buffer';
window.Buffer = window.Buffer || Buffer;

const GameApp = React.lazy(() => import('./game'));
const DutchAuctionApp = React.lazy(() => import('./dutch-auction'));

function App() {
    useEffect(()=>{
        monitors();
    }, []);

    return (<>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={ <GameApp /> } />
                <Route exact path="/game" element={ <GameApp /> } />
                <Route exact path="/dutch-auction" element={ <DutchAuctionApp /> } />
            </Routes>
        </BrowserRouter>
    </>);
}

export default App;
