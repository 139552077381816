import Web3 from 'web3'
import { idDev, isProd } from '../utils/ui-res';

import abiDA from './abi.json';
import abiDAV2 from './LotSeasonManagerV2.json';
import abiReward from './abi-reward.json';
import abiTask from './abi-task.json';
import abiTaskV2 from './abi-task-v2.json';
import erc20 from '../wallets/ERC20.json';

import { getEvmProvider } from "../wallets/wallet-list";
import { GameTexts, Chains } from '../const';
import { zetaERC20s } from '../const/';
import * as API from './api';

import { ethers } from "ethers";

var web3 = new Web3(window.ethereum);

var contractAddress = '';
var contractV2Address = '0x417C67ff38eA8A6F8Ed02279352d9CFb93f80304';
var contract = null;

var taskContractAddr = '0xaa9b4f437f4f83a6d3817b93df65f31be2a36e27';
var taskContractAddrV2 = '0x37F1bB4dd55c6bcaBcCfa759989922B324fC9f09';

function isContractV2(){
    return contractAddress.toLowerCase() === contractV2Address.toLowerCase();    
}

const init = async (data, wallet, chain) => {    
    ethereum = await getEvmProvider(wallet, chain);
    web3 = new Web3(ethereum);

    //doc https://web3js.readthedocs.io/en/v1.10.0/web3-eth-contract.html
    // var rpcUrls = [
    //     'https://zetachain-mainnet.g.allthatnode.com/archive/evm',
    //     'https://zetachain-evm.blockpi.network/v1/rpc/public',
    //     'https://zetachain-mainnet.public.blastapi.io',
    //     'https://7000.rpc.thirdweb.com'
    // ];
    // let i = Math.floor(Math.random() * new Date().getTime())%rpcUrls.length;
    // i= 1;
    // let userProvider = rpcUrls[i];
    // let userProvider = new Web3.providers.HttpProvider(rpcUrls[i]);
    // web3.eth.setProvider(userProvider);
    // console.log('contract ethereum', rpcUrls, i, userProvider);

    let { contractAddr } = data;
    contractAddress = contractAddr;

    let Contract = web3.eth.Contract;
    // Contract.setProvider(userProvider);
    if(isContractV2()){
        contract = new Contract(abiDAV2.abi, contractV2Address);
    }else{
        contract = new Contract(abiDA.abi, contractAddr);        
    }
    return contract;
}

const getUnionInfo = (data, callback) => {
    let { seasonId, address } = data;
    contract.methods.getUnionInfo(seasonId, address).call((err, result) => { 
        if(err){
            console.error('getUnionInfo', data, err);
        }
        callback(result);
    });
}

const getLandInfo = (data, callback) => {
    let {seasonId, p} = data;
    contract.methods.getLandInfo(seasonId, p).call((err, result)=>{
        if(err){
            console.error('getLandInfo', data, err);
        }
        callback(result);
    });
}

const getGameInfo = (data, callback) => {
    let { seasonId } = data;
    contract.methods.getGameInfo(seasonId).call((err, result)=>{
        if(err){
            console.error('getGameInfo', data, err);
        }
        callback(result || {});
    });
}

const getShareInfo = (data, callback) => {
    let { seasonId, address, unionId } = data;
    contract.methods.getShareInfo(seasonId, address, unionId).call((err, result)=>{
        if(err){
            console.error('getShareInfo', data, err);
        }
        callback(result);
    });
}

const getAllRewards = async (seasons, userInfo) => {
    let { address } = userInfo || {};
    let rewards = [];
    let total = 0;

    let prices = await API.getCoinsPrice(zetaERC20s);
    console.log(prices);

    // let prices = {
    //     "0x0000000000000000000000000000000000000000": 0.5834,
    //     "0x45334a5b0a01ce6c260f2b570ec941c680ea62c0": 0.5236,
    //     "0xcba2aeec821b0b119857a9ab39e09b034249681a": 0.5136,
    // };

    for(let season of seasons){
        let { contractAddr, gameContractAddr, sid, tokenAddr = '' } = season;
        if(!contractAddr || !gameContractAddr || !sid){
            break;
        }

        let price = prices[tokenAddr.toLowerCase()] || prices['0x0000000000000000000000000000000000000000'] || 0.6;

        let contract = new web3.eth.Contract(abiDA.abi, contractAddr);
        let contractTask = new web3.eth.Contract(abiTask, taskContractAddr);
        if(contractAddr.toLowerCase() === contractV2Address.toLowerCase()){
            contract = new web3.eth.Contract(abiDAV2.abi, contractAddr);
            contractTask = new web3.eth.Contract(abiTaskV2, taskContractAddrV2);
        }

        let contractReward = new web3.eth.Contract(abiReward.abi, gameContractAddr);

        let isWithdrawed = await contractReward.methods.userWithdrawedFinalAt(address).call() || 0;
        let isWithdrawFinal = isWithdrawed - 0 > 0;

        let gameInfo = {};
        try{
            gameInfo = await contract.methods.getGameInfo(sid).call() || {};
        }catch(err){
            console.log('getAllRewards gameInfo err', err);
        }
        if(!gameInfo.gameEnded){
            break;
        }

        let shareInfo = {};
        let unionInfo = {};
        let userShares = 0;
        let unionTotalShares = 0;
        let unionId = 0;

        let taskReward = {};

        try{
            unionInfo = await contract.methods.getUnionInfo(sid, address).call() || {};
            unionId = unionInfo.unionId;
            shareInfo = await contract.methods.getShareInfo(sid, address, unionId).call() || {};

            taskReward = await contractTask.methods.getReward(sid, address).call() || {};

            // let rrrr = await contractReward.methods.getPoolValue().call() || 0;
            // console.log('rrrr', rrrr);

            if(!isWithdrawFinal){
                userShares = await contractReward.methods.userShares(address).call() || 0;
                unionTotalShares = await contractReward.methods.unionTotalShares(unionId).call() || 0;
            }
        }catch(err){
            console.log('getAllRewards err', err);
        }

        let { winnerAddress = '', winnerUnionId = '' } = gameInfo;
        let isWinner = winnerAddress.toLowerCase() === address.toLowerCase();
        let isWinUnion = winnerUnionId + '' === unionId + '';

        let { totalUnionPoolValue = 0, totalFinalPoolValue = 0 } = gameInfo;
        if(!isWinner || isWithdrawFinal){
            totalFinalPoolValue = 0;
        }
        let conquestValue = 0;
        if(isWinUnion && unionTotalShares > 0 && !isWithdrawFinal){
            conquestValue = (totalUnionPoolValue - 0)*userShares/unionTotalShares;
        }
        
        let stock = shareInfo.totalDivident - 0 || 0;

        let taskRewardValue = (taskReward.reward || 0)/1;
        let claimTs = taskReward.claimTs/1;
        if(claimTs > 0){
            taskRewardValue = 0;
        }
        total += (stock + conquestValue/1 + totalFinalPoolValue/1 + taskRewardValue)*price;
        let rewardItems = {
            seasonId: sid,
            conquest: conquestValue,
            individual: totalFinalPoolValue,
            task: taskRewardValue,
            stock
        };

        console.log('getAllRewards item', 
            { sid, unionId, winnerUnionId, address }, 
            { isWithdrawFinal, isWinner, gameInfo, shareInfo, taskReward }, 
            { total, userShares, unionTotalShares }, rewardItems);
        if(stock > 0 || totalFinalPoolValue > 0 || conquestValue > 0 || taskRewardValue > 0){
            rewards.push({shareInfo, gameInfo, rewardItems, taskReward, price, season});
        }
    };
    console.log('getAllRewards all', { total }, rewards);
    return {total, rewards, back: true };
}

const approveERC20 = async(params) => {
    let { token, amount, address, contractAddress } = params;
    const contractToken = new web3.eth.Contract(erc20.abi, token);
    let result1 = await contractToken.methods.allowance(address, contractAddress).call();
    console.log({result1, amount}, params);

    if(result1/1 >= amount/1){
        return { result: true };
    }

    let max = 10000000;
    let limit = web3.utils.toWei(max.toString(), 'ether');
        limit = web3.utils.toBN(limit);
    let result2 = await contractToken.methods.approve(contractAddress, limit).send({from: address});
    console.log({result2, limit}, params);
    return { result: !!result2.blockHash };
}

const sendUtil = async (data, nx) => {
    let from = data.from || data.address;
    if(!from){
        console.error('sendUtil no address', data);
    }
    let value = data.value || 0;

    let gasLimit = 1000000;
    try{
        gasLimit = await nx.estimateGas({ from, value });
    }catch(err){
        console.error('estimateGas err', err);
    }
    gasLimit = Math.ceil(gasLimit*2);
    gasLimit = web3.utils.toHex(gasLimit);

    let gasPrice =  '25000000000';
    try{
        gasPrice = await web3.eth.getGasPrice();
    }catch(err){
        console.error('getGasPrice err', err);
    }
    gasPrice = web3.utils.toHex(gasPrice);
    
    let params = {
        from,
        to: data.to || contractAddress,
        gasPrice,
        gasLimit,
        value
    };

    try{
        let res = await nx.send(params);
        return {
            result: true,
            hash: res.transactionHash,
            data: res
        }
    }catch(err){
        // alert(JSON.stringify(err))
        console.error('contract err:', err, data, params);
        return {
            result: false,
            msg: err
        };
    }
}

const createSeason = async (data) => {
    let Contract = web3.eth.Contract;
    contract = new Contract(abiDAV2.abi, contractV2Address);

    let { seasonId, startTs, registryFee, registryFeeTokenAddr, tokenAddr, initFinalAmount, paramsEffect, address } = data;
    data.from = address;
    data.to = contractV2Address;

    let nx = contract.methods.createSeason(seasonId, startTs, registryFee, registryFeeTokenAddr, tokenAddr, initFinalAmount, paramsEffect);
    
    if(tokenAddr.toLowerCase() !== '0x0000000000000000000000000000000000000000'){
        //erc20
        let res1 = await approveERC20({ token: tokenAddr, amount: initFinalAmount, address, contractAddress: contractV2Address });
        console.log('createSeason', res1);
        if(!res1.result){
            // alert('approve insuffcient.');
            return res1;
        }
        let res = await sendUtil(data, nx);
        return res;
    }else{
        //zeta
        let res = await sendUtil(data, nx);
        return res;
    }
}

const joinUnion = async (data) => {
    let { seasonId, unionId } = data;
    let nx = contract.methods.joinUnion(seasonId, unionId);
    let res = await sendUtil(data, nx);
    return res;
}

const buyLand = async (data) => {
    //seasonId,LotGame.Point memory p,LotGame.Point memory rp
    let { seasonId, p, rp, value, tokenAddr, address } = data;
    let nx = contract.methods.buyLand(seasonId, p, rp, value);

    data.from = address;
    data.to = contractV2Address;

    if(tokenAddr.toLowerCase() !== '0x0000000000000000000000000000000000000000'){
        let res1 = await approveERC20({ token: tokenAddr, amount: value, address, contractAddress: contractV2Address });
        console.log('buyLand', res1, data);
        if(!res1.result){
            return res1;
        }
        let res = await sendUtil(data, nx);
        return res;
    }else{
        let res = await sendUtil(data, nx);
        return res;
    }
}

const withdrawDividends = async (data) => {
    let { seasonId, contractAddr } = data;
    let Contract = web3.eth.Contract;
    let contract = new Contract(abiDA.abi, contractAddr);
    if( contractAddr.toLowerCase() === contractV2Address.toLowerCase() ){
        contract = new Contract(abiDAV2.abi, contractV2Address);
    }
    let nx = contract.methods.withdrawDividends(seasonId);
    let res = await sendUtil(data, nx);
    return res;
}

const withdrawFinal = async (data) => {
    let { seasonId, contractAddr } = data;
    let Contract = web3.eth.Contract;
    let contract = new Contract(abiDA.abi, contractAddr);
    if( contractAddr.toLowerCase() === contractV2Address.toLowerCase() ){
        contract = new Contract(abiDAV2.abi, contractV2Address);
    }
    let nx = contract.methods.withdrawFinal(seasonId);
    let res = await sendUtil(data, nx);
    return res;
}

const withdrawTaskReward = async (data) => {
    let { seasonId, address, contractAddr } = data;
    let contractTask = new web3.eth.Contract(abiTask, taskContractAddr);
    if(contractAddr.toLowerCase() === contractV2Address.toLowerCase()){
        contractTask = new web3.eth.Contract(abiTaskV2, taskContractAddrV2);
    }
    let nx = contractTask.methods.claimReward(seasonId, address);
    let res = await sendUtil(data, nx);
    return res;
}

export {
    init,
    getUnionInfo,
    getLandInfo,
    getGameInfo,
    getShareInfo,
    getAllRewards,

    createSeason,
    joinUnion,
    buyLand,
    withdrawDividends,
    withdrawFinal,
    withdrawTaskReward
};